import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { AccountLayout } from './Layout/AccountLayout';
import { RestrictedRoute } from './Components/RestrictedRoute/RestrictedRoute';
import { PageSubscriptions, PageAccount, PageAppSettings, PageRenewNow, PageBuyPackages, PageCancelSubscription, DashboardPage, ForgotPassword, ResetPassword, PageNotFound, PortalHome, AuthorizenetPayment, CartHome, CyberSourcePayment, MercadoPagoPayment, PageCart, PageOrderCancelled, PageOrderComplete, ChangePassword, PageLoading } from './Pages';
import { DesignContextProviderWrapper, ShoppingCartContextProvider, HashContext, HashContextProvider, AuthContext, AuthContextProvider, OrderContextProvider, CustomerPortalContextProvider } from './Contexts';
import { PortalLayout } from './Layout/Layout';
import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { PORTAL_VIEWS } from './views';
import { ILanguage } from './Components/SiteHeaderPortal/SiteHeaderPortalContainer';
import moment from 'moment';
import { PayfastPaymentContainer } from './Pages/PayfastPaymentContainer';
import { PageTransactions } from './Pages/Transactions';
import { MomoApiPaymentVerify } from './Pages/MomoApiPaymentVerify';
import { MomoApiPayment } from './Pages/MomoApiPayment';
import { useLocation } from 'react-router-dom';
import { PagePasswordResetNotValid } from './Pages/PasswordResetNotValid';
import { Buffer } from 'buffer';


function App() {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  let code = searchParams.get('code');

  const hash = searchParams.get('h');
  const operation = searchParams.getAll('o')?.pop() ?? "";
  const isOrderComplete = window.location.href.split('&').some((i) => i.includes('oc=order-complete'));

  // TODO Investigate if  order-cancel is even possible
  const isOrderCancel = window.location.href.split('&').some((i) => i.includes('o=order-cancel'));

  if (code == null) {
    code = '';
  } else {
    code = '?code=' + code;
  }

  return <I18nextProvider {...{ i18n }}>{operation === PORTAL_VIEWS.recoverurl ? <RecoverUrl {...{ code, hash }} /> : <Portal {...{ code, hash, operation, isOrderCancel, isOrderComplete }} />}</I18nextProvider>;
}

export function Portal({ code, hash, operation, isOrderComplete, isOrderCancel }: { code: string; hash: string; operation: string; isOrderComplete: boolean; isOrderCancel: boolean }) {
  return (
    <HashContextProvider code={code ?? ''} {...{ hash }} onInvalidHash={() => {}}>
      <ShoppingCartContextProvider>
        <CustomerPortalContextProvider>
          <DesignContextProviderWrapper>
            <AuthContextProvider>
              <Loader>
                <OrderContextProvider>
                  <PortalLayout showHeader={!(isOrderComplete || isOrderCancel)}>
                    <ViewSelector {...{ isOrderCancel, isOrderComplete, operation }} />
                  </PortalLayout>
                </OrderContextProvider>
              </Loader>
            </AuthContextProvider>
          </DesignContextProviderWrapper>
        </CustomerPortalContextProvider>
      </ShoppingCartContextProvider>
    </HashContextProvider>
  );
}

type LoaderProps = {
  children: React.ReactNode;
};

function Loader({ children }: LoaderProps) {
  const { isLoading } = useContext(AuthContext);
  const { client, deployment, service } = useContext(HashContext);
  const [isLoadingTranslations, setIsLoadingTranslations] = useState(true);

  const locales = [
    'en',
    'af',
    'ar-dz',
    'ar-kw',
    'ar-ly',
    'ar-ma',
    'ar-sa',
    'ar-tn',
    'ar',
    'az',
    'be',
    'bg',
    'bm',
    'bn-bd',
    'bn',
    'bo',
    'br',
    'bs',
    'ca',
    'cs',
    'cv',
    'cy',
    'da',
    'de-at',
    'de-ch',
    'de',
    'dv',
    'el',
    'en-au',
    'en-ca',
    'en-gb',
    'en-ie',
    'en-il',
    'en-in',
    'en-nz',
    'en-sg',
    'eo',
    'es-do',
    'es-mx',
    'es-us',
    'es',
    'et',
    'eu',
    'fa',
    'fi',
    'fil',
    'fo',
    'fr-ca',
    'fr-ch',
    'fr',
    'fy',
    'ga',
    'gd',
    'gl',
    'gom-deva',
    'gom-latn',
    'gu',
    'he',
    'hi',
    'hr',
    'hu',
    'hy-am',
    'id',
    'is',
    'it-ch',
    'it',
    'ja',
    'jv',
    'ka',
    'kk',
    'km',
    'kn',
    'ko',
    'ku',
    'ky',
    'lb',
    'lo',
    'lt',
    'lv',
    'me',
    'mi',
    'mk',
    'ml',
    'mn',
    'mr',
    'ms-my',
    'ms',
    'mt',
    'my',
    'nb',
    'ne',
    'nl-be',
    'nl',
    'nn',
    'oc-lnc',
    'pa-in',
    'pl',
    'pt-br',
    'pt',
    'ro',
    'ru',
    'sd',
    'se',
    'si',
    'sk',
    'sl',
    'sq',
    'sr-cyrl',
    'sr',
    'ss',
    'sv',
    'sw',
    'ta',
    'te',
    'tet',
    'tg',
    'th',
    'tk',
    'tl-ph',
    'tlh',
    'tr',
    'tzl',
    'tzm-latn',
    'tzm',
    'ug-cn',
    'uk',
    'ur',
    'uz-latn',
    'uz',
    'vi',
    'x-pseudo',
    'yo',
    'zh-cn',
    'zh-hk',
    'zh-mo',
    'zh-tw',
  ];

  useEffect(() => {
    const fetchCountries = async () => {
      const { data: deploymentData } = await axios.get(
        `${process.env.REACT_APP_TVMS_API_BASE}/api/v1/deployments/find?client=${client}&s=${JSON.stringify({
          name: deployment,
        })}`
      );
      const serviceLanguages = deploymentData.services.find(({ name }) => name === service).languages as ILanguage[];
      serviceLanguages.forEach((lang) => {
        if (lang.code !== 'en') {
          try {
            if (locales.find((l) => l === lang.code.toLowerCase())) {
              import('moment/locale/' + lang.code.toLowerCase());
            }
          } catch (e) {
            console.log(e);
          }
        }
      });
      try {
        moment().locale(serviceLanguages[0].code.toLowerCase());
      } catch (e) {
        console.log(e);
      }
      await getLanguageFiles(serviceLanguages, client, deployment, service);
    };

    fetchCountries();
  }, [client, deployment, service]);

  const getLanguageFiles = async (languages, client, deployment, service) => {
    try {
      let resources = {};

      await Promise.all(
        languages.map(async (language) => {
          const url = `https://cloudtv.akamaized.net/${client}/${deployment}/${service}/carts/translations/${language.name}.json`;

          const response = await fetch(url);

          if (!response.ok) {
            throw new Error('Error during download');
          }

          const result = await response.json();
          resources[language.code] = {
            translation: result.language,
          };
        })
      );

      await i18n.init({
        resources,
      });
      setIsLoadingTranslations(false);
    } catch (err) {
      console.error('Error while loading:', err);
      return { success: false };
    }
  };

  return <>{isLoadingTranslations ? <PageLoading /> : children}</>;
}

const ViewSelector = ({ operation, isOrderComplete, isOrderCancel }: { operation: string; isOrderComplete: boolean; isOrderCancel: boolean }) => {
  const { customer } = useContext(AuthContext);

  const accountWrapper = (element: JSX.Element) => (
    <RestrictedRoute>
      <AccountLayout children={[element]} />
    </RestrictedRoute>
  );

  const accountDisallowedWrapper = (element: JSX.Element) => {
    if (customer) {
      return <PortalHome />;
    } else {
      return element;
    }
  };

  if (isOrderComplete) {
    return <PageOrderComplete />;
  }
  if (isOrderCancel) {
    return <PageOrderCancelled />;
  }

  switch (operation) {
    case PORTAL_VIEWS.shop:
      return accountDisallowedWrapper(<CartHome />);
    case PORTAL_VIEWS.cart:
      return <PageCart />;
    // case PORTAL_VIEWS.checkout:
    //   return <PageCheckout />;
    case PORTAL_VIEWS.orderComplete:
      return <PageOrderComplete />;
    case PORTAL_VIEWS.orderCancelled:
      return <PageOrderCancelled />;
    // case PORTAL_VIEWS.productDetails:
    //   return <PageProduct />;
    case PORTAL_VIEWS.cybersourcePayment:
      return <CyberSourcePayment />;
    case PORTAL_VIEWS.mercadopagoPayment:
      return <MercadoPagoPayment />;
    case PORTAL_VIEWS.authorizenetPayment:
      return <AuthorizenetPayment />;
    case PORTAL_VIEWS.payfastPayment:
      return <PayfastPaymentContainer />;
    case PORTAL_VIEWS.momoApiPayment:
      return <MomoApiPayment />;
    case PORTAL_VIEWS.momoApiPaymentVerify:
      return <MomoApiPaymentVerify />;
    case PORTAL_VIEWS.account:
      return accountWrapper(<PageAccount />);
    case PORTAL_VIEWS.addpackages:
      return accountWrapper(<PageBuyPackages />);
    case PORTAL_VIEWS.appsettings:
      return accountWrapper(<PageAppSettings />);
    case PORTAL_VIEWS.cancelsubscription:
      return accountWrapper(<PageCancelSubscription />);
    case PORTAL_VIEWS.dashboard:
      return accountWrapper(<DashboardPage />);
    case PORTAL_VIEWS.forgotpassword:
      return <ForgotPassword />;
    case PORTAL_VIEWS.home:
      return <PortalHome />;
    case PORTAL_VIEWS.passwordchange:
      return accountWrapper(<ChangePassword />);
    case PORTAL_VIEWS.passwordreset:
      return <ResetPassword />;
    case PORTAL_VIEWS.renew:
      return accountWrapper(<PageRenewNow />);
    case PORTAL_VIEWS.subscriptions:
      return accountWrapper(<PageSubscriptions />);
    case PORTAL_VIEWS.transactions:
      return accountWrapper(<PageTransactions />);
    default:
      return <PageNotFound />;
  }
};

const RecoverUrl = ({ code, hash }: { code: string; hash: string }) => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const [url, setUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    const fetchShortenedUrl = async () => {
      try {
        if (!hash) {
          return;
        }

        const { client, deployment, service } = JSON.parse(Buffer.from(hash || '', 'base64').toString());

        const recoveryUrl = new URL(`${process.env.REACT_APP_TVMS_API_BASE}/api/v1/r/${searchParams.get('id')}`);
        recoveryUrl.searchParams.set('instance', client);
        recoveryUrl.searchParams.set('cms', deployment);
        recoveryUrl.searchParams.set('crm', service);
        const { data } = await axios.get(recoveryUrl.toString());
        setUrl(data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchShortenedUrl();
  });

  if (url) {
    const newUrl = new URL(url);
    newUrl.searchParams.set('t', searchParams.get('t'));
    window.location.assign(newUrl.toString());
  }
  else {
    return (
      <>
      {hash &&
      <HashContextProvider code={code ?? ''} {...{ hash }} onInvalidHash={() => {}}>
        <ShoppingCartContextProvider>
          <CustomerPortalContextProvider>
            <DesignContextProviderWrapper>
              <AuthContextProvider>
                <Loader>
                  <OrderContextProvider>
                    <PortalLayout showHeader={true}>
                      <PagePasswordResetNotValid />
                    </PortalLayout>
                  </OrderContextProvider>
                </Loader>
              </AuthContextProvider>
            </DesignContextProviderWrapper>
          </CustomerPortalContextProvider>
        </ShoppingCartContextProvider>
      </HashContextProvider>
      }
      {!hash &&
      <DesignContextProviderWrapper>
        <PortalLayout showHeader={false}>
          <PagePasswordResetNotValid />
        </PortalLayout>
      </DesignContextProviderWrapper>
      }
      </>
    );
  }

  return <div></div>;
};

export default App;
