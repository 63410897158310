import { useTranslation } from 'react-i18next';
import { CustomerTitle } from '@mwaretv/database/build/backend/enums/customers/customerTitle';
import { useForm } from 'react-hook-form';
import { capitalizeFirstLetter } from '../../Utilities/StringUtilities';
import { HashContext, OrderContext, ShoppingCartContext } from '../../Contexts';
import { Input, SubmitButton, Form, Select, HorizontalRow, ErrorMessage, CustomPhoneInput, Label } from '../Form/Form';
import { Title } from '../Title/Title';
import { useContext, useState } from 'react';
import { Country } from '../../Types/Country';
import styles from './CustomerEditForm.module.scss';
import {notification} from 'antd';
import { AddressProfile } from '@mwaretv/database/build/backend/enums/billing/addressProfile';
import { Customer } from '../../interfaces';
import { updateCustomer } from '../../Services/Customer';
import { RegionDropdown } from 'react-country-region-selector';

type CustomerFormProps = {
  customer: Customer;
  currencies: [];
  countries: Country[];
};

export function CustomerEditForm({ customer, countries }: CustomerFormProps) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    setValue,
  } = useForm<Customer>();
  const { t } = useTranslation();
  const { client, deployment, service } = useContext(HashContext);
  const {
    customer: { mandatory_email, mandatory_phone, address },
  } = useContext(ShoppingCartContext);
  const { order } = useContext(OrderContext);
  const [notifier, context] = notification.useNotification();
  const [region, setRegion] = useState(customer.state);

  return (
    <Form
      onSubmit={handleSubmit(async (data: Partial<Customer>) => {
       // const country = countries.find((c) => c.name === data.country);

        try {
          // TODO Rewrite this
          if (data.mobile === '') {
            data.mobile = undefined;
          }
          if (data.phone === '') {
            data.phone = undefined;
          }
          if (data.email === '') {
            data.email = undefined;
          }
          await updateCustomer(
            {
              instance: client,
              cms: deployment,
              crm: service,
            },
            customer._id,
            {
              ...data
            }
          );
          notifier.success({ message: t('customer_form_success') });
        } catch (error) {
          notifier.error({ message: t('customer_form_error') });
        }
      })}
    >
      {context}
      <div
        style={{
          border: '1px solid #dedede',
          padding: 20,
          backgroundColor: '#fff',
          borderRadius: 5,
        }}
      >
        <div style={{ width: 100 }}>
          <Select defaultValue={customer.title} {...register('title', { required: true })} label={t('customer_form_labels_title')}>
            {Object.values(CustomerTitle).map((x, index) => (
              <option key={index} value={x}>
                {capitalizeFirstLetter(x)}
              </option>
            ))}
          </Select>
        </div>
        <HorizontalRow>
          <div>
            <Input defaultValue={customer.first_name} {...register('first_name', { required: t('field_validation_required') })} label={t('customer_form_labels_first_name')} />
            <ErrorMessage error={errors.first_name?.message} />
          </div>
          <div>
            <Input defaultValue={customer.last_name} {...register('last_name', { required: t('field_validation_required') })} label={t('customer_form_labels_last_name')} />
            <ErrorMessage error={errors.last_name?.message} />
          </div>
        </HorizontalRow>
        <div>
          <Input disabled={true} defaultValue={customer.email} {...register('email', { required: false })} label={t('customer_form_labels_email')} />
          <ErrorMessage error={errors.email?.message} />
        </div>
        <HorizontalRow>
          <div>
            <CustomPhoneInput defaultValue={customer.phone ?? ''} required={false} control={control} name="phone" label={t('customer_form_labels_phone')} />
            <ErrorMessage error={errors.phone?.message} />
          </div>
          <div>
            <CustomPhoneInput defaultValue={customer.mobile ?? ''} required={mandatory_phone} control={control} name="mobile" label={t('customer_form_labels_mobile')} />
            <ErrorMessage error={errors.mobile?.message} />
          </div>
        </HorizontalRow>
      </div>

      {address !== AddressProfile.NONE && (
        <div
          style={{
            border: '1px solid #dedede',
            padding: 20,
            backgroundColor: '#fff',
            borderRadius: 5,
          }}
        >
          <Title level={3} title={t('customer_form_address')} />
          <HorizontalRow>
            <Input value={customer.country} disabled label={t('customer_form_labels_country')} />
            <Label>
              <span>{t('customer_form_labels_state')}</span>
              <RegionDropdown  disabled={true} country={customer.country} value={customer.state} onChange={(val) => setRegion(val)} classes={styles.regionDropdown} />
            </Label>
          </HorizontalRow>

          {address === AddressProfile.FULL && (
            <>
              <HorizontalRow>
                <div>
                  <Input defaultValue={customer.city} {...register('city', { required: t('field_validation_required') })} label={t('customer_form_labels_city')} />
                  <ErrorMessage error={errors.city?.message} />
                </div>
                <div>
                  <Input defaultValue={customer.zipcode} {...register('zipcode', { required: t('field_validation_required') })} label={t('customer_form_labels_zipcode')} />
                  <ErrorMessage error={errors.zipcode?.message} />
                </div>
              </HorizontalRow>
              <div>
                <Input defaultValue={customer.street} {...register('street', { required: t('field_validation_required') })} label={t('customer_form_labels_street')} />
                <ErrorMessage error={errors.street?.message} />
              </div>
            </>
          )}
        </div>
      )}
      <SubmitButton>{t('customer_form_update_account')}</SubmitButton>
    </Form>
  );
}
