import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { CookiesProvider } from 'react-cookie';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import BugsnagPerformance from '@bugsnag/browser-performance';
import App from './App';
import './index.scss';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
      retry: false,
    },
  },
});

Bugsnag.start({
  apiKey: 'fcd671adaeb5edcfbfdd19c25bcf3e9b',
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.NODE_ENV,
  enabledReleaseStages: ['production'],
});

BugsnagPerformance.start({ apiKey: 'fcd671adaeb5edcfbfdd19c25bcf3e9b' });

class NoopErrorBoundary extends React.Component {
  // define your Noop Error Boundary here
}
var ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React) ?? NoopErrorBoundary;

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <CookiesProvider>
        <App />
      </CookiesProvider>
    </QueryClientProvider>
  </BrowserRouter>
);
