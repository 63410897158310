import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from '../Components/Container/Container';
import { ImageGallery } from '../Components/ImageGallery/ImageGallery';
import { Title } from '../Components/Title/Title';
import { ProductDescription } from '../Components/ProductDescription/ProductDescription';
import styles from './Product.module.scss';
import { Loader } from '../Components/Loader/Loader';
import { TextWithButton } from '../Components/TextWithButton/TextWithButton';
import { ChannelGrid } from '../Components/ChannelGrid/ChannelGrid';
import { getTranslatedName, getTranslatedDescription } from '../Utilities/ProductUtilities';
import { HashContext } from '../Contexts';
import { VerticalSpacer } from '../Components/VerticalSpacer/VerticalSpacer';
import { Modal } from 'antd';
import { useProduct } from '../Services/Product';
 
export function PageProduct(params: { onCancel: any; id: string }) {
  const { t, i18n } = useTranslation();
  const { client, deployment, service } = useContext(HashContext);
  const { isLoading, isError, data, refetch } = useProduct(client, deployment, service, params.id as string);

  return (
    <Modal visible={true} width={'80%'} footer={false} closable={true} onCancel={params.onCancel}>
      <Container>
        <div className={styles.page}>
          {isLoading && <Loader />}
          {isError && <TextWithButton text={t('product_overview_error')} buttonText={t('product_overview_retry')} onButtonClick={() => refetch()} />}
          {!isLoading && !isError && (
            <div>
              <div style={{ marginBottom: 20 }}>
                <Title level={1} title={getTranslatedName(data.data, i18n.language)} />
              </div>
              <VerticalSpacer>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    width: '100%',
                    border: '1px solid #dedede',
                    borderRadius: 5,
                    padding: 10,
                  }}
                >
                  <div style={{ width: '50%' }}>
                    <ImageGallery images={data.data.external.images} />
                  </div>
                  <div style={{ margin: 20, width: '50%' }}>
                    <div style={{ marginBottom: 10 }}>
                      <Title level={3} title={getTranslatedName(data.data, i18n.language)} />
                      <ProductDescription description={getTranslatedDescription(data.data, i18n.language) || ""} />
                    </div>

                    {/* <ProductItemDetails items={data.data.items} /> */}
                  </div>
                </div>
              </VerticalSpacer>
              {data?.data?.items?.bundles?.channels?.length > 0 &&
              <VerticalSpacer>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    border: '1px solid #dedede',
                    borderRadius: 5,
                    padding: 10,
                    marginTop: 20,
                  }}
                >
                  {data.data.items.bundles.channels.map((bundle, index) => {
                    return <ChannelGrid bundle={bundle} index={index} />;
                  })}
                </div>
              </VerticalSpacer>
              }
            </div>
          )}
        </div>
      </Container>
    </Modal>
  );
}
