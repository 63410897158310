import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from '../Components/Container/Container';
import { OrderContext, ShoppingCartContext } from '../Contexts';
import { PrimaryButton } from '../Components/Button/Button';
import { Title } from '../Components/Title/Title';
import { isMobile } from 'react-device-detect';
import { ShoppingCartFlow, ShoppingCartWizard } from '../Components/ShoppingCartFlow/ShoppingCartFlow';

export function CartHome() {
  const { t } = useTranslation();
  const { website_portal } = useContext(ShoppingCartContext);
  const { baseProducts, extraProducts, order } = useContext(OrderContext);
  const [onStartOrder, setOnStartOrder] = useState(false);
  const [initialStep] = useState(order.payment?.gateway != null ? ShoppingCartWizard.paymentMethod : undefined);

  return (
    <Container>
      {!onStartOrder && (
        <div
          style={{
            marginTop: 25,
            marginBottom: 50,
            width: isMobile ? '60%' : '100%',
            paddingLeft: isMobile ? '20%' : 0,
            paddingRight: isMobile ? '20%' : 0,
          }}
        >
          <Title level={1} title={t('checkout_already_a_customer')} />
          <div style={{ display: 'flex', width: '100%' }}>
            <div style={{ width: isMobile ? '100%' : '70%' }}>
              {t('checkout_already_a_customer_info')}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  marginTop: 15,
                }}
              >
                <PrimaryButton
                  onClick={() => {
                    window.open(website_portal, '_blank');
                  }}
                >
                  {t('login_form_redirect_customerportal')}
                </PrimaryButton>
              </div>
              <div style={{ marginTop: 40, fontWeight: 'bold' }}>{t('select_service_next')}</div>
            </div>
          </div>
        </div>
      )}
      <ShoppingCartFlow setOnStartOrder={setOnStartOrder} baseProducts={baseProducts} extraProducts={extraProducts} initialStep={initialStep} />
    </Container>
  );
}
