import { Card, Table, TablePaginationConfig } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Container } from '../Components/Container/Container';
import { Input } from '../Components/Form/Form';
import { AuthContext, DesignContext, HashContext } from '../Contexts';
import { TransactionReporting } from '@mwaretv/database/build/backend/models/reporting/transactions/transaction';
import { getTransactions } from '../Services/Transactions';
import { TransactionType } from '@mwaretv/database/build/backend/models/reporting/chartdesigns/chartDesignEnums';

interface TableParams {
  pagination?: TablePaginationConfig;
}

const Details = ({ data }: { data: TransactionReporting }) => {
  const {
    forms: { inputs },
  } = useContext(DesignContext);
  const { t } = useTranslation();

  const backgroundColor = inputs['background-color'];

  console.log(backgroundColor);

  return (
    <>
      {data.transaction_maintype === TransactionType.SUBSCRIPTION &&
      <Card title={t('transactions_product')} style={{ marginTop: 16 }}>
        <Input style={{ backgroundColor }} label={t('transactions_name')} value={data.subscription?.name} disabled />
        <Input style={{ backgroundColor }} label={t('transactions_type')} value={data.subscription?.type} disabled />
        <Input style={{ backgroundColor }} label={t('transactions_variant')} value={data.subscription?.variant} disabled />
        <Input style={{ backgroundColor }} label={t('transactions_plan_name')} value={data.customer?.plan?.name} disabled />
        <Input style={{ backgroundColor }} label={t('transactions_plan_duration')} value={data.customer?.plan?.duration} disabled />
        <Input style={{ backgroundColor }} label={t('transactions_plan_duration_in')} value={data.customer?.plan?.duration_period_in ? t(data.customer?.plan?.duration_period_in) : undefined} disabled />
      </Card>
      }
      {data.transaction_maintype === TransactionType.PAYPERVIEW &&
      <Card title={t('transactions_product')} style={{ marginTop: 16 }}>
        <Input style={{ backgroundColor }} label={t('transactions_name')} value={data.payperview?.content?.name} disabled />
        <Input style={{ backgroundColor }} label={t('transactions_type')} value={data.payperview?.content?.mainType} disabled />
      </Card>
      }
    </>
  );
};

export const PageTransactions = () => {
  const { t } = useTranslation();
  const { client, deployment, service } = useContext(HashContext);
  const {
    customer: { _id },
  } = useContext(AuthContext);

  const [transactions, setTransactions] = useState([]);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTransactions = async () => {
      setLoading(true);
      const { data } = await getTransactions(client, deployment, service, _id, tableParams.pagination.current, tableParams.pagination.pageSize);

      setTableParams((prevTableParams) => ({
        pagination: {
          ...prevTableParams.pagination,
          total: data.totalDocs,
        },
      }));

      setTransactions(data.docs);
      setLoading(false);
    };

    fetchTransactions();
  }, [JSON.stringify(tableParams)]);

  const handleTableChange = (pagination) => {
    setTableParams({
      pagination,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setTransactions([]);
    }
  };

  const columns: ColumnsType<TransactionReporting> = [
    {
      title: t('transactions_transaction_date'),
      dataIndex: 'date_unix',
      width: '15%',
      render: (value) => new Date(value * 1000).toString()
    },
    {
      title: t('transactions_transaction_type'),
      dataIndex: 'transaction_subtype',
      width: '15%',
    },
    {
      title: t('transactions_transaction_source'),
      dataIndex: 'source',
      width: '15%',
    },
    {
      title: t('transactions_transaction_currency'),
      width: '15%',
      render: (value, record) => {
        return record.customer?.billing_currency;
      }
    },
    {
      title: t('transactions_amount_vat'),
      dataIndex: 'amount_vat',
      width: '15%',
      render: (value) => value.toFixed(2)
    },
    {
      title: t('transactions_amount_inc_vat'),
      dataIndex: 'amount_inc_vat',
      width: '15%',
      render: (value) => value.toFixed(2)
    }
  ];

  return (
    <Container>
      <Table
        columns={columns}
        rowKey={(record) => record._id}
        dataSource={transactions}
        pagination={tableParams.pagination}
        loading={loading}
        onChange={handleTableChange}
        expandable={{
          columnTitle: t('transactions_details'),
          expandedRowRender: (record) => <Details data={record} />,
        }}
      />
    </Container>
  );
};
